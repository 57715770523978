<script>
import ProductCard from '../components/ProductCard.vue';

export default {
  components: {
    ProductCard,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      leftObserver: null,
      rightObserver: null,
      canScrollLeft: false,
      canScrollRight: false,
      scrollWidth: 0,
    };
  },
  computed: {
    title() {
      return this.block.fields?.Title || '';
    },
    heading() {
      return this.block.fields.HeadingLevel?.value || 'h4';
    },
    products() {
      return (
        this.block.fields?.BlockProducts ||
        this.block.fields?.BlockConditions ||
        []
      );
    },
  },
  methods: {
    createObserver(root, cb) {
      return new IntersectionObserver(
          (entries) => {
          entries.forEach((entry) => {
            cb(entry);
          });
        },
        {
          root,
          rootMargin: '0px',
          threshold: 0.9,
        }
      );
    },
    initArrows() {
      const root = this.$refs.productList;
      if (root.scrollWidth <= root.clientWidth) {
        return;
      }

      this.leftObserver = this.createObserver(
        root,
        (entry) => (this.canScrollLeft = !entry.isIntersecting)
      );

      const firstElement = this.$refs['product0']?.[0]?.$el;

      if (firstElement) {
        this.leftObserver.observe(firstElement);
      }

      this.rightObserver = this.createObserver(
        root,
        (entry) => (this.canScrollRight = !entry.isIntersecting)
      );

      const lastElement = this.$refs[`product${this.products.length - 1}`]?.[0]?.$el;
      if (lastElement) {
        this.rightObserver.observe(lastElement);
      }
    },
    removeArrows() {
      this.leftObserver?.disconnect();
      this.leftObserver = null;
      this.rightObserver?.disconnect();
      this.rightObserver = null;
    },
    scrollLeft() {
      this.$refs?.productList?.scrollBy(-this.scrollWidth, 0);
    },
    scrollRight() {
      this.$refs?.productList?.scrollBy(this.scrollWidth, 0);
    },
    setScrollWidth() {
      this.scrollWidth = this.$refs.productListContainer?.clientWidth;
    },
  },
  mounted() {
    this.setScrollWidth();
    window.addEventListener('resize', this.setScrollWidth);
    this.$nextTick(() => {
      this.initArrows();
    });
  },
  beforeUnmount() {
    this.removeArrows();
    window.removeEventListener('resize', this.setScrollWidth);
  },
};
</script>
<template>
  <div class="product-block__list-container" ref="productListContainer">
    <component :is="heading" v-if="title" class="product-block__title">{{ title }}</component>
    <div class="product-block__list" ref="productList">
      <product-card
        v-for="(product, index) in products"
        :key="index"
        :product="product"
        class="product-block__product"
        :class="['product-block__product' + index]"
        :ref="`product${index}`"
      />
    </div>
    <div class="product-block__arrows">
      <div
        class="product-block__scroll-left"
        v-if="canScrollLeft"
        @click="scrollLeft()"
      >
        <img
          class="product-block__scroll-left-icon"
          src="../static/icons/arrow_left_black_icon.svg"
        />
      </div>
      <div
        class="product-block__scroll-right"
        v-if="canScrollRight"
        @click="scrollRight()"
      >
        <img
          class="product-block__scroll-right-icon"
          src="../static/icons/arrow_right_black_icon.svg"
        />
      </div>
    </div>
  </div>
</template>

<style>
.product-block__list-container {
  margin: 1rem auto;
  max-width: calc(1210px + 3rem);
  width: 100%;
  overflow: hidden;
}

.product-block__title {
  margin: 0 1rem;
  color: black;
}

.product-block__list {
  display: flex;
  flex-direction: row;
  scroll-snap-type: x mandatory;
  scroll-snap-align: center;
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0.5rem 0.5rem 30px 0.5rem;
}

.product-block__list::-webkit-scrollbar {
  display: none; /*hide scroll for Chrome, Safari, and Opera */
}

.product-block__product {
  list-style-type: none;
  flex-shrink: 0;
  scroll-snap-align: start;
}

.product-block__product.product-card {
  width: 80%;
}

.product-block__arrows {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  margin-top: -1rem;
}

.product-block__scroll-left {
  margin-right: auto;
  cursor: pointer;
}

.product-block__scroll-right {
  margin-left: auto;
  cursor: pointer;
}

@media (--tablet) {
  .product-block__list {
    margin: 0.5rem 0.75rem 30px;
  }

  .product-block__product.product-card {
    width: 50%;
  }
}

@media (--desktop) {
  .product-block__list {
    padding-left: 0;
  }

  .product-block__product.product-card {
    width: 25%;
  }
}

@media (--tabletAndDesktop) {
  .product-block__title {
    margin-left: 1.5rem;
  }
}
</style>